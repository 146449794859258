.container-home {
    min-height: 100vh;
    width: 100%;
}

.home-header {
    box-sizing: border-box;
    width: 100%;
    height: 75px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    background: white;
}

.home-logo {
    display: flex;
    height: 75%;
}

.home-container-filter-search {
    display: flex;
    align-items: center;
    height: 35px;
}

.home-container-filter {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 400px;
    height: 35px;
}

.home-container-filter-input {
    height: 100%;
    border: none;
    outline: none;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #F0F5F3 0% 0% no-repeat;
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
    padding:0;
    padding-left: 15px;
    width: 90%;
}

.buttonFilter {
    width: 35px;
    height: 100%;
    border:none;
    outline:none;
    background: #828282;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.button-filter-buscar {
    display: flex;
    height: 100%;
    justify-content: center;
    margin-left: 15px;
}

.button-filter-buscar {
    height: 100%;
}

.button-filter-buscar button {
    height: 100%;
    width: 80px;
    font-size: 1em;
    background: #22B573;
    font-weight: 500;
    color: white;
    border: none;
    outline: none;
    display: flex;
    justify-content: center;
    align-items: center;
}

.home-container-sair {
    display: flex;
    align-items: center;
    justify-content: center;
}

.home-container-sair button {
    border:none;
    outline: none;
    background: transparent;
    font-weight: bold;
}

.home-body {
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: 100%;
}

.home-box-tickets {
    display: flex;
    flex-direction: column;
    max-height: 65vh;
    overflow-y: auto;
    align-items: flex-start;
    animation: showTickets .5s 200ms ease-in-out backwards;
}

@keyframes showTickets {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.home-box-tickets::-webkit-scrollbar { width:7px; background:#e3e0d6; } /* configurando scroll */
.home-box-tickets::-webkit-scrollbar-track { background: rgba(0,0,0,0.1); }
.home-box-tickets::-webkit-scrollbar-thumb { border-radius:10px; background:#8b8778;  }

.home-tickets-word {
    font-size: 1em;
    font-weight: bold;
}

.home-container-tickets {
    min-width: 550px;
}


.home-box-text {

}

.home-text-1 {
    font-size: 1em;
    color: #707070;
    margin-top: 25px;
}

.home-text-2 {
    margin-bottom: 25px;
    font-size: 1.8em;
    color: #22B573;
    font-weight: bold;
}

.container-filter-tab {
    box-sizing: border-box;
    position: absolute;
    top: 55px;
    width: 400px;
    background: white;
    box-shadow: 0px 4px 14px #00000026;
    padding: 15px;
    animation: showFilter ease-in-out .5s backwards;
}

@media only screen and (max-width:700px) {
    .container-filter-tab {
        max-width: 65%;
    }
}

.title-filter-tab {
    display: flex;
    align-items: center;
    width: 23%;
    justify-content: space-between;
    white-space: nowrap;
}

.filter-tab-status {
    display: flex;
    align-items: center;
    padding-top: 5px;
    padding-bottom: 5px;
}

@keyframes showFilter {
    0% {
        opacity: 0;
        scale: 0.9;
    }

    100% {
        opacity: 1;
        scale: 1;
    }
}

.filter-tab-select {
    background: #F2F2F2 0% 0% no-repeat padding-box;
    color: #828282;
    font-size: 0.9em;
    box-sizing: border-box;
    padding: 5px;
    border: none;
    margin-left: 5px;
    cursor: pointer;
}

.filter-tab-select-option {
    color: #828282;
    font-size: 0.9em;
    font-weight: bold;
}

.filter-tab-data {
    display: flex;
    align-items: center;
}

.filter-tab-data {
    color: #828282;
    font-size: 0.9em;
}

.filter-tab-datepicker {
    background: #F2F2F2 0% 0% no-repeat padding-box;
    color: #828282;
    font-size: 1em;
    box-sizing: border-box;
    padding: 7px;
    padding-left: 3px;
    padding-right: 3px;
    border: none;
    margin-left: 5px;
    cursor: pointer;
    width: 65%;
    text-align: center;
}

.arrowDown {
    width: 10px;
    height: 5px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    clip-path: polygon(50% 0, 0 100%, 100% 100%);
    transform: rotate(180deg);
}