.container-ticket-body {
	width: 500px;
	display: flex;
	padding: 5px;
	box-sizing: border-box;
}

.container-ticket {
	width: 100%;
}

.container-ticket-top {
	background: #ffffff 0% 0% no-repeat padding-box;
	width: 100%;
	box-shadow: 3px 4px 9px #0000000a;
	border-radius: 5px;
	opacity: 1;
	margin-left: 0;
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 15px;
}

.container-ticket-left {
	width: 60%;
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.ticket-left-items {
	width: 75%;
}

.container-ticket-data-empresa {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 5px;
	padding-left: 0;
	box-sizing: border-box;
	width: 350px;
}

.text-data {
	color: #828282;
	margin-right: 10px;
}

.text-empresa {
	color: #828282;
	text-align: left;
	width: 60%;
}

.borda-ticket-status {
	border-top-left-radius: 5px;
	border-bottom-left-radius: 5px;
	width: 5px;
}

.text-status {
	padding: 5px;
	padding-left: 0;
	align-items: center;
    color: #828282;
    display: flex;
    width: 300px;
}

.container-options-status {
    display: flex;
    justify-content: center;
    align-items: center;
}

.status-alterar-status {
    cursor: pointer;
    height: 25px;
}

.text-atendimento {
	font-weight: bold;
	font-size: 1em;
	color: #4e4e4e;
	padding: 5px;
	padding-left: 0;
	white-space: nowrap;
}

.ticket-ver {
	font-weight: bold;
	margin-right: 25px;
	cursor: pointer;
	height: 75px;
	display: flex;
	align-items: center;
}

.container-close-button {
	margin: 5px;
	margin-top: 0;
	margin-bottom: auto;
	border-radius: 2px;
	height: 25px;
	width: 35px;
	cursor: pointer;
	display: flex;
	justify-content: center;
	align-items: center;
}

.close-button {
	background: #4e4e4e 0% 0% no-repeat padding-box;
	border-radius: 2px;
	height: 4px;
	width: 20px;
}

.ticket-status-color {
    font-weight: bold;
    margin-left: 5px;
}

.ticket-status-edit {
	display: flex;
    align-items: center;
    cursor:pointer;
}

.ticket-status-edit img {
    height: 20px;
    margin-left: 5px;
}

.img-status-alterar:first-child {
    margin-left: 15px;
}

.img-status-alterar {
    height: 20px;
    margin-left: 5px;
    cursor: pointer;
    transition: 0.5s;
}

.img-status-alterar:hover {
    transform: scale(1.1);
    opacity: 0.9;
}

.ticket-select {
	background: #f2f2f2 0% 0% no-repeat padding-box;
	color: #828282;
	font-size: 0.9em;
	font-weight: bold;
	box-sizing: border-box;
	padding: 5px;
	border: none;
	margin-left: 5px;
	cursor: pointer;
}

.ticket-select-option {
	color: #828282;
	font-size: 0.9em;
	font-weight: bold;
}

.ticket-status-bola {
}

.container-ticket-bottom {
	width: 100%;
	background: #ffffff 0% 0% no-repeat padding-box;
	display: flex;
	flex-direction: column;
	padding: 15px;
	padding-top: 0;
}

.container-ticket-linha {
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	margin-bottom: 15px;
}

.ticket-linha {
	background: #d3d3d3 0% 0% no-repeat padding-box;
	height: 2px;
	width: 100%;
}

.ticket-objetivo {
	margin-bottom: 15px;
}

.ticket-objetivo-1 {
	color: #828282;
	font-size: 0.9em;
	white-space: nowrap;
}

.ticket-objetivo-2 {
	font-size: 1em;
	font-weight: bold;
	color: #4e4e4e;
}

.ticket-artigo {
	margin-bottom: 15px;
}

.ticket-artigo-1 {
	color: #828282;
	font-size: 0.9em;
}

.ticket-artigo-2 {
	font-size: 1em;
	font-weight: bold;
	color: #4e4e4e;
}

.ticket-dados-linha-1 {
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 90%;
	margin-bottom: 15px;
}

.ticket-telefone {
	width: 60%;
}

.ticket-telefone-1 {
	color: #828282;
	font-size: 0.9em;
}

.ticket-telefone-2 {
	font-size: 1em;
	font-weight: bold;
	color: #4e4e4e;
}

.ticket-empresa {
	width: 40%;
}

.ticket-empresa-1 {
	color: #828282;
	font-size: 0.9em;
}

.ticket-empresa-2 {
	font-size: 1em;
	font-weight: bold;
	color: #4e4e4e;
}

.ticket-dados-linha-2 {
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 90%;
	margin-bottom: 15px;
}

.ticket-data {
	width: 60%;
}

.ticket-data-1 {
	color: #828282;
	font-size: 0.9em;
}

.ticket-data-2 {
	font-size: 1em;
	font-weight: bold;
	color: #4e4e4e;
}

.ticket-horario {
	width: 40%;
}

.ticket-horario-1 {
	color: #828282;
	font-size: 0.9em;
}

.ticket-horario-2 {
	font-size: 1em;
	font-weight: bold;
	color: #4e4e4e;
}

.container-ticket-form {
}

.ticket-form-resposta {
	color: #828282;
	font-size: 0.9em;
}

.form-textfield {
	width: 100%;
	height: 100px;
	padding: 5px;
	box-sizing: border-box;
	border: 2px solid #ccc;
	border-radius: 4px;
	background: #f2f2f2 0% 0% no-repeat padding-box;
	resize: none;
	outline: none;
	font-size: 1em;
}

.container-form-button {
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-top: 15px;
}

.form-button-1 {
	background: #828282;
	color: white;
	font-weight: bold;
	font-size: 0.9em;
	width: 50%;
	padding: 7px;
	border: none;
	outline: none;
	height: 35px;
	display: flex;
	justify-content: center;
	align-items: center;
}

.form-button-2 {
	background: #22b573;
	color: white;
	font-weight: bold;
	font-size: 0.9em;
	width: 25%;
	padding: 7px;
	height: 35px;
	border: none;
	outline: none;
	display: flex;
	justify-content: center;
	align-items: center;
}

@keyframes show {
	0% {
		display: none;
	}
	1% {
		height: 0%;
		display: flex;
	}
	100% {
		height: 100%;
	}
}

@keyframes hide {
	0% {
		height: 100%;
	}
	100% {
		display: none;
		height: 0%;
	}
}
