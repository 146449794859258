.container-login {
    min-height: 100vh;
    background: white;
}

.title {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: justify;
    padding-top: 5%;
}

.header-title{
    display: grid;
    align-items: center;
    justify-content: center;
    text-align: justify;
}

.subtitle-1 {
    color: #818181;
    font-size: 15px;
}

.subtitle-2 {
    margin-top: 3px;
    color: black;
    font-size: 20px;
}

.manipula-img {
    margin-right: 25px;
}

.img-logo{
    width: 100%;
}

.paragrafo-1 {
    color: #818181;
    font-size: 12px;
    text-align: justify;
    margin-top: 20px;
    margin-right: 2%;
}

.texto {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    margin-top: 1%;
    margin-left: 4%;
    margin-right: 4%;
}

.modalPosition{
    display: flex;
    align-items: center;
    justify-content: center;
}

.modalLogin {
    display: table;
    align-content: center;
    justify-content: left;
    border: 1px solid #c0c0c0;
    border-radius: 15px;
    width: 20%;
    max-width: 30%;
    padding: 15px;
    margin-top: 4%;
}

.p-modal{
    margin-top: 5%;
    color: black;
    font-size: 25px;
}

.area-input{
    display: grid;
}

.input-all{
    background-color: #f0f0f0;
    margin-top: 10px;
}

.label-input{
    margin-top: 10px;
    font-size: 10px;
    color: black;
}

.MuiInputBase-input {
    height: 15px!important;
}

.footer-modal-login{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 20px 0 20px 0;
}

.button-proximo{
    border: 0px;
    background-color: rgb(31, 185, 88);
    color: white;
    width: 75px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.button-proximo:hover {
    opacity: 0.9;
}

.footer{
    display: flex;
    align-items: center;
    justify-content: center;
}

.label-input-check-login{
    font-size: 11px;
    color: #818181;
}
.check{
    display: flex;
    align-items: center;
}
